import request from '@/utils/request'
import qs from 'qs'
// 申请服务商
export function authororderlist (works_id) {
  let data = {
    works_id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/applyfws',
    method: 'post',
    data
  })
}
// 放弃投稿
export function giveupcontribution(worksid) {
  let data = {
    worksid
  }
  data = qs.stringify(data)
  return request({
    url: '/author/giveupcontribution',
    method: 'post',
    data
  })
}
// 订单确认
export function confirmorder(id) {
  let data = {
   id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/Confirm',
    method: 'post',
    data
  })
}

// 订单确认
export function AddTransaction(w_id,trade_No,trade_type,trade_mode) {
  let data = {
    w_id,trade_No,trade_type,trade_mode
  }
  data = qs.stringify(data)
  return request({
    url: '/author/AddTransaction',
    method: 'post',
    data
  })
}
// 申请服务商
export function applyfws(works_id) {
  let data = {
    works_id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/applyfws',
    method: 'post',
    data
  })
}

// 申请退款
export function applyRefund(works_id) {
  let data = {
    works_id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/applyRefund',
    method: 'post',
    data
  })
}
